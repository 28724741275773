header{
    text-align: center;
    display: flex;
    /* justify-content:center ; */
    align-items: center;
    justify-content: space-evenly;
    font-size: 20px;
    
}

header h2{
    font-size: 60px;
}

header img{
    width: 600px;
}

.towhithe p{
  color: white;
}


.parellal{

    position: relative;
    height: 100vh;
    overflow: hidden;
}

.parellal{
    /* position: absolute; */

  background-image: url('/public/img/parallax.png');

height: 200px;

  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

.medeatils{
  width: 70%;
  margin: 0 auto;
}


.medeatils{
  text-align: center;
  

}

h3{
    color: #66bb6a;
    padding: 10px;
    font-weight: bold;
    text-align: center;
}

.parallax-content {
    color: white;
    /* position: relative; */
    width: 80%;
    margin: 0 auto;
    z-index: 1;
    padding: 20px;
    text-align: center;
  }


  .coretheme{
    padding: 30px;
  }
  .coretheme h3 span{
    color:#66bb6a;
   
  }
  .coretheme ul span{
    color:#000000;

    font-weight: bold;

  }

 .whychoose ul span{
    color:#000000;

    font-weight: bold;


 }

 .whychoose{
    padding: 30px;
  }


  .inindoor{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }


  .inoutdoor{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .hidd{
   color: white;
  }
  .hidd a{
    text-decoration: none;
    color: white;
  }


  .cycle{
    text-align: center;
    width: 80%;
    margin: 0 auto  ;
  }
  .cyclese{
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .clickto{
    margin-top: 20px;
    padding: 10px;   
    padding-left: 20px;
    padding-right: 20px;
    /* border-radius: 30%;   */
  }

  .cyclese{
    margin-bottom: 40px;

  }

  .cycle2{
   
    text-align: center;
    width: 80%;
    margin: 0 auto  ;
  }
  .aboutcycle2 h2{
    margin-top: 40px;
    font-weight: bold;
  }


  .cycle2 h2{
    /* font-weight: bold; */
  }

  .membership{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-self: center;
    align-items: center;
  }


.cyclese2{
    display: flex;
    justify-content: space-evenly;
}






  @media only screen and (max-width: 768px){


    header{
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        /* justify-content:center ; */
        align-items: center;
        justify-content: space-evenly;
        font-size: 20px;
        
    }
    header h2{
        font-size: 40px;
    }
    header img{
        width: 360px;
    }
    .parellal{
        /* position: absolute; */
    
      background-image: url('/public/img/parallaxm.png');
    
    height: 380px;
    
     
    }
    .cycle{
        margin-top: 40px;
    }
    .membership{
        flex-direction: column;
    }
    .inoutdoor{
        display: flex;
        flex-direction: column;
    }

    .inindoor{
        display: flex;
        flex-direction: column;
    }
    .card {
    margin-bottom: 10px;
    }
    .medeatils{
      width: 100%;
      padding: 10px;
      /* margin: 0 auto; */
      font-size: 10px;
    }
    .parallax-content-ta {
      color: white;
      /* position: relative; */
      width: 80%;
      margin: 0 auto;
      z-index: 1;
      font-size: 12px;
      padding: 10px;
      text-align: center;
    }
  
    

  }
body{
    width: 100%;
}
.social-ma h1{

    text-align: center;
    /* font-weight: 300; */
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
margin-bottom: 20px;
    }
.heroimg{
    /* background-color: red; */
    height: 350px;
}
.heroimg img{
   height: 100%;
   width: auto;
}
    .heroimg{
        display: flex;
        justify-content: center;
        align-items:center ;
    }
    .herop{
        text-align: center;
        width: 85%;
        margin: 0 auto;
    }
    .firpart{
        
        width: 85%;
       
        margin: 0 auto;
    }

    .firstt{
        margin-top: 50px;
       
    }

    .piller{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
       align-items: center;
    }

    h5{
        color: #66BB6A;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 20px;
        
        
    }
    
    .containerr{
        /* display: flex;
        flex-direction: column;
     align-items: center;
     text-align: left; */
     width: 85%;
     margin: 0 auto;
     text-align: center;
        
    }
    .func{
        /* width: 70%; */
        margin: 0 auto;
        text-align: center;
      
    }
       .func2{
        width: 85%;
        margin: 0 auto;
        text-align: center;
      
    }

    .pointss{
        width: 85%;
         margin: 0 auto;
    }
  .pointss span{
    font-weight: bold;
}

.foupart{
    text-align: left;
    width: 85%;
    margin: 0 auto;
    justify-content: center;
}

.lastpart{
    text-align: left;
    width: 85%;
    margin: 0 auto;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .heroimg img{
       width: 360px;
       height: auto;

    }
    .piller{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
}
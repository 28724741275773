/* Slideshow.css */
.slideshow-container {
  max-width: 550px; /* Adjust this value to fit your design */
 /* margin: 0 auto;*/
 margin-bottom: 30px;
  /* background-color:red; */
}
.boldspan{
  /* font-weight: bold; */
}
.slide img {

  
  width: 100%;
}

img {
  /* margin-bottom: 30px; */
}
 .peravurani{
    width: 90%;
  
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
 }

 .social-ma{
    width: 90%;
    margin: 0 auto;
 }
 
 .pvito{
   width: 80%;
   margin: 0 auto;

 }

 .perav{
   width: 80%;
   margin: 0 auto;
   display: flex;
   flex-direction: row-reverse;
   justify-content: space-between;
   align-items: center;
 }

 @media only screen and (max-width: 768px)  {

   
 .perav{
   display: flex;
   flex-direction: column;
 }
 .peravurani{
   padding: 10px;
   text-align: left;
 }
   
 }
.about-flex {
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

#About-head {
    text-align: center;
    /* font-weight: 300; */
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text", "Myriad Set Pro", "SF Pro Icons", "Apple Legacy Chevron", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #66BB6A;
}

.about-card {
    width: 395px;
    height: 384.96px;
    margin-bottom: 50px;
    /* background-color: aqua;  */

}

.about-card img {
    width: 395px;
    height: 266.98px;
}

.about-card p {
    width: 385px;
    height: 118px;

    font-weight: 400;
    size: 14px;
    line-height: 20px;
    color: #505050;
    margin-bottom: 50px;

}

.heighttt img {
    width: 860px;
}

.heighttt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.structuretit h2 {

    margin-top: 20px;
}


#heroslider {
    background-color: black;
    height: 40%;
    width: 100%;

}


.herosliderimg {
    height: 600px;
}

.carousel-inner {

    padding-top: 0px;
    margin-bottom: 0px;
}

#heroslider h3,
p {

    z-index: 999;

}

#heroslider h3,
p {}

.tobecolwhite {
    color: white;
}

#heroslider::after {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    /* Adjust the height of the overlay as needed */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    pointer-events: none;
}

.slide img {
    /* height: 400px; */
}

.listprimary {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
}

.listprimarylist {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listprimarylist img {
    margin-bottom: 10px;

}

.listsecondary img {
    padding: 40px;

}

.listsecondary {
    display: none;
}



@media only screen and (max-width: 480px) {
    .herosliderimg {
        height: 300px;

    }

    .listprimary {
        display: none;
    }

    .listsecondary {
        display: flex;
    }

    .listsecondary img {
        padding: 2px;

    }
}

















@media only screen and (max-width: 768px) {
    .about-flex {
        margin: 0 auto;
        margin-bottom: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    #About-head {
        margin-top: 50px;
    }

    .about-card {
        width: 500px;
        margin-bottom: 50px;
    }

    .about-card {
        margin-bottom: 100px;
    }

    .about-card p {
        margin-top: 5px;
        width: 90%;
        margin-left: 20px;
        margin-bottom: 50px;
        /* justify-content: center; */
    }

    .about-card img {
        width: 100%;
        height: 280.98px;
        border-radius: 25px;
    }

    .heighttt img {
        width: 700px;
    }
}

@media only screen and (max-width: 480px) {

    .about-card {
        width: 80%;
    }

    .about-card p {
        text-align: justify;
    }

    .heighttt img {
        width: 360px;
    }
}
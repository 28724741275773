/* .structure img{
    width: 900px;
    height: 900px;
    margin: auto;
    justify-content: center; 
    margin-left: 380px;
} */

.tamimg{
    margin-top: 20px;
}

.structure{
    /* background-color: aqua; */
    width: 100%;
    margin: 0 auto;
    /* height: 600px; */
}
.structure img{
    width: 80%;
    margin-bottom: 20px;
    margin: 0 auto;
    display: flex;

}

.titlebar h4{
    color: rgb(42, 42, 42);
    font-weight: 500;
}

.Tabs {
  
}

.towertitile{
    font-weight:  700;
    margin-top: 20px;
}

.peravuranitow {
    background-color: green;
    color: white;
}

.peravuranitow{
    background-color: #66BB6A;
}



.floww{
    display: flex;
    justify-content: center;
    align-items: center;
}
.floww img{
    width: 700px;
    margin-bottom: 50px;

}

.arroww{
    display: flex;
    justify-content: center;
    align-items: center;
}

.roww{
    display: flex;
    justify-content: center;
    align-items: center;
}
.arroww img{
    width: 200px;
    margin-bottom: 50px;

}
.roww img{
    width: 1200px;
    margin-bottom: 50px;

}
.arrow{
    text-align: center;
}
.arrow p{
font-weight: bold;
}
.arrow h2 span{
    color: #66BB6A;

}
.row{
    text-align: center;
    
}
.row img{
    margin-bottom: 20px;
}
.row h2 span{
    color: #66BB6A;

}
.row h2{
    margin-bottom: 30px;
}


@media only screen and (max-width: 768px) {
.floww img{

    max-width: 100%;
    height: auto;
    margin: 40px;
}

}
@media only screen and (max-width: 768px){
    /* .structure img{
        margin: 0 auto;
        align-items: center;
        display: block;
    
        width: 400px;
        height: 400px;
        
    } */
}

@media only screen and (max-width: 480px) {
    /* .structure img{
        margin-left: 60px;
        /* overflow-x: hidden; 
        overflow: hidden;
    } */

}
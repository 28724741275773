 
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@500&display=swap');
  @import url('https://db.onlinewebfonts.com/c/d6243b42d786410a86f0bafee83d503a?family=SF+Pro+Text+Semibold');
  @import url('https://fonts.cdnfonts.com/css/sf-pro-display');


 
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');
 

.container{
  display: flex;
  /* background-color: aqua; */
  justify-content: space-between;
  align-items: center;
  /* width: max-content; */
  /* background-color: red; */
}

.profileicon img{
  width: 25px;
}

   .nave-menu a{
       
/* background-color: #305e32; */
       font-size: 14px;
        text-decoration: none;
        /* font-family: 'Open Sans', sans-serif; */
        /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
        /* font-family: 'SF Pro Regular', sans-serif; */
        /* font-family: "SF Pro Text Thin"; */
        font-family: 'Roboto Condensed', sans-serif;
        /* font-family: 'Oswald', sans-serif; */
        /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
        font-weight: 500;

        color: #424245;
         /* color: #1D1D1F;
        color: #6E6E73;  */

        /* margin-left: 20px; */
        
        
        color: #424245;
        /* margin-left:5px; */
      }


/*hover*/
      /* .me-auto a{
        display: inline;
        margin-right: 20px;

      } */


      .bg-body-tertiary {
         --bs-bg-opacity: 0;
         background-color: red;
      }
     
      

     
/*hover*/



      .nave-menu a {
        /* position: relative; Create a stacking context for the pseudo-element */
       
        
        text-decoration: none; /* Remove the default underline */
        transition: all 0.5s ease; 
        /* margin-left: 15px; */
      }
      .me-auto{
        margin-left: 10px;
        /* background-color: blue; */
        justify-content: space-evenly;
      }

      .logo{
        text-align: center;
      }

      .prosus{
        font-size: 10px;
        /* margin-bottom: 5px; */
        color: #000000;
        font-weight: 600;
      }
      .nave-menu{
        
        /* overflow: hidden; */
        /* margin-left: 150px; */
        /* background-color: brown; */
        width: 100%;
        
       
        /* background-color: brown; */
        
      }
      .logo img                                  {
        width: 200px;
      }


      @media (min-width: 1200px)
      {
        .container, .container-lg, .container-md, .container-sm, .container-xl 
        {
          max-width: 1300px;
        }
    }


@media only screen and (max-width: 768px) 
{
 
.nave-menu{
    margin-left:0px;
  }
}




@media only screen and (max-width: 480px) {
 .nave-menu{
        margin-left:1px;
      }
}











#myButton {
  background-color: green;
  border: none;
  color: white;
  padding: 15px 25px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease;
}

#myButton:hover {
  background-color: #2ecc71;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

/* Glossy Effect */
#myButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px 10px 0 0;
  transition: all 0.3s ease;
  z-index: -1;
}

#myButton:hover:before {
  top: 50%;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

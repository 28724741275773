
.social-ma h1{

    text-align: center;
    /* font-weight: 300; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
    /* font-family: Philosopher; */
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
margin-bottom: 20px;
    }

.floww{
    display: flex;
    justify-content: center;
    align-items: center;
}
.floww img{
    width: 700px;
    margin-bottom: 50px;

}

.arroww{
    display: flex;
    justify-content: center;
    align-items: center;
}

.roww{
    display: flex;
    justify-content: center;
    align-items: center;
}
.arroww img{
    width: 200px;
    margin-bottom: 50px;

}
.roww img{
    width: 1200px;
    margin-bottom: 50px;

}
.arrow{
    text-align: center;
}
.arrow p{
font-weight: bold;
}
.arrow h2 span{
    color: #66BB6A;

}
.row{
    text-align: center;
    margin-top: 30px;
    
}
.row img{
    margin-bottom: 20px;
}
.row h2 span{
    color: #66BB6A;

}
.row h2{
    margin-bottom: 30px;
}


@media only screen and (max-width: 768px) {
.floww img{

    max-width: 100%;
    height: auto;
    margin: 40px;
}

}
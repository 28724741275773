.social-ma{
    padding: 10px;
}

@media only screen and (max-width: 768px) {
    .social-ma{
        padding: 10px;
       
    }

    .goalpoints {
        width: 100%;
        text-align: left;
    }
}
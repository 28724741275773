.howwrk{
    /* background-color: aqua; */
    width: 80%;
    margin: auto;
    /* text-align: center; */
}

#RDhead{
    /* margin: 20px 20px; */
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0em;
    text-align:center;
    color: #66BB6A;
    padding: 50px;
}

.howwrk img{
    width: 60%;
    margin: auto;
    margin: 30px 20%;
    /* align-items: center; */
}
.howwrk h4{
    font-size: 20px;
    font-weight: bold;
}
.howwrk p{
    /* width: 80%; */
}
.howwrk h3{
    text-align: center;
}
.howwrk #hthreepara{
    width: 100%;
    padding: 10px;
    letter-spacing: 1px;
    /* background-color: aqua; */
    text-align: center;
}
.howwrk #intropara{
    width: 100%;
    /* background-color: aqua; */
    /* alig-items: center; */
    padding: 20px 50px;
    text-align: center;
}
.howwrk #headfour{
    padding-left: 50px;
    /* background-color: aquamarine; */
}
.howwrk #headfourpara{
    /* background-color: blueviolet; */
    letter-spacing: 1px;
    /* margin-left: 30px; */
    word-spacing: 2px;
    text-align: left;
    padding-left:90px;
}


@media only screen and (max-width: 480px) {
    .howwrk{
    width: 100%;
    margin:0;
    overflow-x: hidden;
    }
    #RDhead{
        font-size: 22px; 
        padding:0;
        text-align:center;
    }
    .howwrk h4{
        font-size: 15;
    }
    .howwrk p{
        width: 100%;

    }
    .howwrk #intropara{
        padding: 0%;
    }
    .howwrk #headfourpara{
        padding: 5%;
    }
    .howwrk #headfour{
        padding-left: 10px;
        /* margin: ; */
    }
    .howwrk #intropara{
        padding: 10px 10px;
        text-align: left;
    }
}
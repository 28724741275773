.social-maa h1{
font-size: 40px;
    font-weight: 600;
    /* line-height: 57px; */
    letter-spacing: 0em;
    /* text-align: left; */
    color: #66BB6A;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
}

.invitation{
    display: flex;
    align-items: center;
    justify-content: center;

}


.invitation img{
width: 780px;
box-shadow: 0 0 0 rgba(105, 105, 105, 0.5); 
transition: box-shadow 0.3s ease-in-out;

}

.invitation img:hover {
    box-shadow: 10px 10px  20px rgba(0, 0, 0, 0.5); /* Shadow on hover with the desired values */
  }

@media only screen and (max-width: 480px) {

    .social-maa h1{
        font-size: 25px;
            font-weight: 600;
            /* line-height: 57px; */
            letter-spacing: 0em;
            /* text-align: left; */
            color: #66BB6A;
            margin-bottom: 20px;
            padding: 10px;
            text-align: center;
        }
        
        .invitation{
            display: flex;
            align-items: center;
            justify-content: center;
        
        }
        
        
        .invitation img{
        width: 380px;
        
        }

}
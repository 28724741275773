.visionmi .toleftneed{
    text-align: left;
}

.collabrate{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.collabrate img{
    width: 250px;
    margin: 10px;

}

#awardpara span{
    color: #66bb6a;
    font-weight: 700;
}

/* #goalaim{
    display: flex;
    justify-content: center;
    align-items: center;
}
 */

 #coaching img{
width: 100%;
height: 500px;
 }

 .donationinfo li{
    list-style: none;
 }
    .donationinformation{
        display: flex;
        width: 70%;
        margin: 0 auto;
        justify-content:  space-evenly;
        align-content: center;
        align-items: center;
 }
 .donationinformation img{
    width: 200px;
    height: 100%;

 }


 @media only screen and (max-width: 768px) {

    #coaching img{
        width: 100%;
        height: auto;
         }

         
.collabrate{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

         .collabrate img{
            width: 150px;
            margin: 10px;
        
        }

        .donationinformation img{
           display: none;
        
         }

         .donationinformation{
            display: flex;
            width: 100%;
            margin: 0 auto;
          
     }
 }
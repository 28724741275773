
.Contact h1{
    text-align: center;
    /* font-weight: 300; */
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
}

body{
  
}


.contactuss{

    
   
}
.Contact{
    color: #535353;
    display: flex;
    
justify-content: space-around;
align-items: center;

}

.ifrmarecontact{
    margin-bottom: 30px;
}

.contactaddress{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}
@media only screen and (max-width: 768px){

    .Contact{
        display: flex;
        flex-direction: column;

    }
   .ifrmarecontact{
        margin: auto;
        align-items: center;
        margin-top: 80px;
        margin-bottom: 100px;
        width: 400px;
    } 
    .contactaddress{
        align-items: center;
    }
}
#mhead{
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0em;
    text-align:center;
    color: #66BB6A;
    margin-bottom: 0px;

}
#mheadd{
    font-size: 25px;
    font-weight: 400;
    line-height: 57px;
    letter-spacing: 0em;
    text-align:center;
    color: #66BB6A;
    margin-bottom: 10px;

}


.bankk{
    display: flex;
    
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 20px;
}
img{
    width:350px;
}
.membertab{
width: 70%;
margin-left: 100px;
margin-top: 50px;
margin-bottom: 150px;
margin: auto;
/* background-color: aqua; */
}
.membercontent h2{
    text-align: center;
}
.membercontent span{
    color: green;
}
.first-mcontent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.first-mcontent ul li{
    width: 450px;
    margin-top: 10px;
}
.membertab h2{

}
.second-mcontent{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-evenly;
}
.second-mcontent p{
    /* text-align: center; */
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
}
.membertab a{
    font-size: 30px;
    color: #66BB6A;
    text-align: center;
}
.membertab iframe{
    margin-top: 50px;
    margin-bottom: 100px;
}
.Memberfprmcontent h1{
    line-height: 57px;
    letter-spacing: 0em;
    text-align:center;
    color: #66BB6A;
}

.offer span{
    color: rgb(132, 132, 132);
    text-decoration: line-through;
    font-size: 25px;
}

.Memberfprmcontent{
    display: flex;
    justify-content: space-between;
}
.offer{
    line-height: 57px;
    letter-spacing: 0em;
    text-align:center;
    color: #66BB6A;
}
.offer{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.farmersonly{
    color: red;
    font-size: 18px;
}
.membertab .Memberfprmcontent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}
.membertab .Memberfprmcontent ul li{
margin: 60px 0px;
}
.membertab .Memberfprmcontent h4{
    color: #66BB6A;
    margin-top: 50px;
}
.membershiptype{
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.membershiptype img{
    margin-top: 40px;
   
    width: 1000px;
}


@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px); /* Adjust the vertical distance */
    }
    100% {
      transform: translateY(0);
    }
  }

  
  .pulse {
    display: inline-block;
    /* width: 100px;
    height: 100px; */

   
    animation: pulse 1.5s infinite;
  }
.customercare{
    position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
  z-index: 9999; /* Adjust the z-index to make sure it's above other elements */
/* Background color for the icon container */
  /* padding: 10px; */
/* For a circular icon container, adjust as needed */

}
.customercare img{
    width: 120px;   
    animation: pulse 1.5s infinite;

}

@media only screen and (max-width: 768px)  {
    .first-mcontent{
        display: flex;
        flex-direction: column;
    }
    .membertab .Memberfprmcontent{
        display: flex;
        flex-direction: column;
    }
    .membertab iframe{
        margin: auto;
        align-items: center;
        margin-bottom: 100px;
        width: 400px;
    }
    .offer{
        display: flex;
        flex-direction: column;
     
    }
    .specialoffer{
        margin-top: 30px;
    }
    .membershiptype img{
        width: 360px;
    }

    #mhead{
        /* font-family: Philosopher; */
        /* font-family: 'Signika', sans-serif; */
        font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
        font-size: 35px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: 0em;
        text-align:center;
        color: #66BB6A;
        margin-bottom: 0px;
    
    }
}
@media only screen and (max-width: 480px) {

    .Membership{
        overflow-x: hidden;
        /* background-color: #66BB6A; */
    }
    .Memberfprmcontent{
        align-items:flex-start;

    }
    .membertab iframe{
        padding-right: 50px;
    }
    .first-mcontent{
        width: 500px;
        overflow: hidden;
    }

    .first-mcontent ul li{
     font-size: 15px;
     padding-right: 70px;
     padding-left:10px;
    }
    .membercontent h2{
        font-size: 23px;
        padding-left: 10px;
        padding-right: 10px;
    }
    #member-benifits{
        margin-top: 30px;
        margin-bottom: 20px;
        padding-left: 20px;
    }
    .customercare img{
        width: 80px;
    }
    .customercare img{
          
        animation: 1.5s infinite;
    
    }
    
}
.social-main h1{

    text-align: center;
    /* font-weight: 300; */
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
    }
.social-content{
display: flex;
flex-direction: row;
justify-content: space-evenly;
}
    .social-content img {
        width: 100px;
    }
    .social-box{
        margin-top: 50px;
        width: 450px;
        /* background-color: green; */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 70px;
      
    }
    .social-box h2{
        font-weight:bold;
        font-size: 1rem;
    }
    .social-box p{
        text-align: left;
        padding: 10px 40px;
        width: 80%;
        margin-bottom: 10px;
       
     }
     .social-box li{

     }
     .goals h1{
            color: rgba(5, 6, 5, 0.675);
            font-weight: 100;

     }
     .goals h1{ 
    text-align: center;
    }
    .goalsspan{
        color: rgb(0, 0, 0);
        font-weight: 500;
    }
    .goals span{
        color: rgba(0, 11, 1, 0.845);
        font-weight: bold;
        text-align: left;
    }
   .susgoals ul{
    margin-bottom: 40px;
    margin-left: 20px;
   }
   .susgoal{
    margin-left: 300px;
    display: flex;
    justify-content:flex-start;
   }
   .susgoal img{
    width: 200px;
   }
   .goals ul li {
    /* margin-top: 100px; */
    margin-bottom: 40px;
    
    font-size: 15px;
   }
   .goals ul{
    margin-left: 50px;
   margin-top: 50px;
   }

   @media only screen and (max-width: 768px) {

    .social-content{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        justify-content: space-evenly;
        }
        .susgoal{
            margin: auto;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
           }
   }

   @media only screen and (max-width: 480px) {

    .social-box p{
        text-align: left;
    }
   
    .goals h1{ 
        margin-bottom: 10px;
    }

    .goals ul{
        margin-right: 50px;
    }
    .social-main h1{
        margin-top: 50px;
    }
}
.questbox{
  padding: 20px,
}

.questbox {
    max-width: 600px; /* Adjust as needed */
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
  }
  
  .questbox h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .questbox p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .questbox a {
    display: inline-block;
    padding: 10px 20px;
    /* background-color: #007bff; */
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .questbox a:hover {
    /* background-color: #0056b3; */
  }
  
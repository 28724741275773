.intro{
    
    width: 80%;
    margin: 0 auto;

}

.intro img{
    width: 100%;
    height: auto;

}
.teams{
    margin-top: 50px;
    text-align: center;
}

.titletext{
    margin-right:20px ;


}
.part2{
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.leftside{
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-content: center;
    text-align: center;
    
}
.titletext{
    text-align: center; 
    margin-top: 20px;
    margin-bottom: 20px;
}
.cardtitle{
    text-align: center;
    color:  #000000;;
}
.cardtitle span{
    color: #66BB6A;
}

.part2 button{
    align-self: center;
    justify-content: center;
    align-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cards{
    /* background-color:brown; */
    display: flex;
    /* margin-left: 20px; */
}

.cards .card{
    background-color: #c3ffc6;
    margin: 10px;
    /* margin-left: 20px;
    margin-bottom: 20px; */
}

.cards2{
 
    /* background-color:brown; */
    display: flex;
    margin-bottom: 20px;
    /* margin-left: 20px; */
}

.cards2 .card{
    background-color: #c3ffc6;
    margin: 10px;
    /* margin-left: 20px; */
}

.freepik{
    margin-top: 30px;
}
.teams h5 span{
color: #66BB6A;
}

.heroim img{
    
    width:40px;
    justify-content: center;
}
.heroim{
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    width: 400px;
    margin: 0 auto;
    
}


.mobteam{
    display: none;
}

@media only screen and (max-width: 768px)  {
    .part2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .part2 iframe{
       width: 500px;
       
    }
    .mobteam{
        display: block;
    }
    .deskteam{
        display: none;
    }
    .cards{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
     .cards2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}


@media only screen and (max-width: 480px) {

    .part2 iframe{
        width: 300px;
        
     }
}
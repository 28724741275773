
.medalcards{
  margin: 20px;
}

.medalcards-container .medalcards {
  margin-bottom: 20px;
}

.medalcards-container .medalcards {
  display: flex;
 justify-content: center;
}



.medalcards-container .medalcards .card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.medalcards-container .medalcards .card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.medalcards-container .medalcards .card .card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.medalcards-container .medalcards .card .card-body {
  padding: 20px;
}

.medalcards-container .medalcards .card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.medalcards-container .medalcards .card .card-text {
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 20px;
}

.medalcards-container .medalcards .card .btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #66BB6A;
  color: #fff;
  border: none;
}

.medalcards-container .medalcards .card .btn:hover {
  background-color: #66BB6A;
}









/* CSS for BenefitsTable component */
.benefits-table {
  margin-top: 20px;
  display: block;
}

.benefits-table h2 {
  text-align: center;
  margin-bottom: 30px;
}

.benefits-table .table-container {
  overflow-x: auto;
}

.benefits-table .table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.benefits-table .table th,
.benefits-table .table td {
  padding: 12px 15px;
  text-align: left;
}

.benefits-table .table th {
  background-color: #66BB6A;
  color: #fff;
}

.benefits-table .table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.benefits-table .table tbody tr:hover {
  background-color: #ddd;
}









.medaltitle{
  margin-top: 30px;
}

.medalparagraph{
  margin-top: 20px;
  margin-bottom: 40px;
}

.medalheroimg{
  width: 600px;
}
.footer{
    width: 100%;
    height: 40vh;
    background-color:#2A2C30;
     color: rgba(255, 255, 255, 0.757);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
}

.footercol{
    background-color:#2A2C30;
display: flex;
justify-content: space-evenly;
padding: 20px;

}
.footercon {
    /* Optional: Add styling to your flex items */
    width: 30%;
    padding: 10px;
    /* background-color: #e0e0e0; */
  }

  .footersite a{
    text-decoration: none;
    color: #FFFFFF;

  }
  .footersite li{
    list-style: none;
 

  }
  .rachlist{

color: white;
  }
  .rachlist{
    display: flex;
    align-items: center;
  }


  .footersite{
     /* margin-left: 90px; */
    justify-content: center;
  }

.zhafootlogo{
width: 250px;

}
.footercon h3{
    color: white;
    text-align: left;
}

.footer img{
    width: 250px;
    margin-top: 10px;
    margin-left: 80px  ;
}
.Fconimg{
    display: flex;
    flex-direction: row;
    /* background-color: #1ab321; */
}
.transkey{
    width: 150px;
}
.footer ul li {
    list-style: none;
    font-size: 16px;
    margin: 10px 5px; 
    font-weight: 400;
    line-height: 18.75px;
    
    margin-top: 10px;
    /* background-color: aqua; */
    /* margin-right: 50px; */

}
.footer ul li a{
    text-decoration: none;
    color:#FFFFFF;
    margin-left: -20px; 
    
   
}
.footer p{
    font-size: 16px;
    width: 557px;
    height: 132px;
    margin-top: 44px;
    margin-left: 80px;
color: #FFFFFF;
font-weight: 400;
line-height: 32.96px;
letter-spacing: 2%;


}
.firstcol{
    display: flex;
    flex-direction: column;
    padding: 10px;
   

    /* align-items: center;
    justify-content: center; */
}
.footercon i{
    color: white;
    font-size: 25px;
    padding: 20px;

}

.rachlist i {
    margin: 10px;
}

.social{
    width: 250px;
    /* background-color: aqua; */
    display:flex ;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    
}
.social .socialicon{
    width: 50px;
     height: 50px;
     background-color:#66BB6A;
     border-radius: 50%;
     
}
.social .socialicon:hover{
    transform: translateY(3px);
}
.social .socialicon i{
    color: white;
    font-size: 20px;
    margin-left: 15px;
    margin-top: 13px;
    /* text-align: center; */
}
.FCONE{
    width: 700px;
    /* background-color: aqua; */
}

    

.footer h3{

margin-top:80px;
font-weight: 600;
font-size: 20px;
line-height: 34.6px;

color: #FFFFFF;

/* text-align: center; */


}
.footer ul{
    /* display: flex;  */
    /* flex-direction: column; */
    /* align-items:flex-start;
    text-align:left;


}
.FCTWO {
    width: 270px;
height: 100%;
}

.FCTHE,.FCFOR {

    width: 230px;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: flex-start; */
    justify-content: flex-start;
    /* background-color: rgb(66, 187, 57); */
    /* display: flex; */
    /* flex-direction:column; */
    /* align-items: flex-start; */
    /* justify-content:flex-start; */
}
.copyright{
    background-color: #2A2C30;
}
.copyright #underline{
    /* margin-top: 10px; */
    width: 90%;
    margin-left: 50px;
    height: 5px;
    background-color: rgb(243, 243, 243);
}
#copytext{
    text-align: end;
    /* margin-left: px; */
   
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    
}
.FCTWO{
    text-align: center;
}
.FCTHE{
    text-align: center;
}
.FCFOR{
    text-align: center;
}
.Footal{
    
    display: flex;
    width: 40%;
    flex-direction: row;
    margin-right: 50px;
    margin-left: 150px;
    margin: auto;
    justify-content: space-between;
}
.footercopyright a{
    text-decoration: none;
    color: white;

}
.footercopyright {
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.footerlinee{
    width: 50px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
   
}
.footercopyrightpc{
    color: white;
    text-align: left;
}

.footercopyrightcontent {
padding: 10px;
 
}

.towhithe h6{
    color: white;
}


 

#flexcontainer{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}
.rachlist a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
}
.footfull{
    background-color: #2A2C30;
}

.FCONE .Transkey{
    margin-top: 100px;
}
@media only screen and (max-width: 768px) 
{
    .footercol{
        display: flex;
        flex-direction: column;
    }
    .footercon{
        width: 100%;
    }


    .footer{
        overflow: hidden;
    }
    .footer{
        width: 100%;
        height: auto;
        background-color:#2A2C30;
        color: rgba(255, 255, 255, 0.757);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
       
    }
    .footer img{
        width: 200px;
        /* margin: 0 auto; */
        /* display: block; */
        /* justify-content: center; */
        margin-right: 80px;
    }

    .social{
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 80px;
        margin-top: 40px;
    }
    .social .socialicon{
        margin-top: 20px;
       
       
        
        /* width: 30px; */
        /* margin-left: 10px; */

    }
    .social .socialicon i{
        /* width: 10px; */
    }
    .copyright{
        margin: 0 auto;
        align-content: center;
        align-items: center;
      
    }
    #copytext{
        margin: 0 auto;
        
     
       text-align: center;
      
      
      
    }
    .copyright #underline{
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .copyright #copytext{
       
        margin-bottom: 10px;
    }
    .footal{
        display: flex;
        flex-direction: column;
        
    }
    /* .FCONE .txt{
        width: auto;
        margin: 0 auto;
    } */
    .footer h3{
        margin-bottom: 2px;
    }
    .FCONE{
        margin-top: 50px;
        font-size: 10px;
        
        align-items: center;
        display: flex;
        flex-direction: column;
        
        
    }
    .FCTWO{
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .FCTHE{
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .FCFOR{
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .footer .FCONE p {
        width: 60%; 
        text-align: center;
        font-size: 15px;
        margin: 0 auto;
       /* margin-right: 140px;
       margin-left: auto;  */
        
    }
    .Footal{
        display: flex;
        flex-direction: column;
    }
    

}
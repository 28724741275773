
.Donateus {
    text-align: center;
  }
  
  .donate-button {
    width: 120px; /* Adjust button width as needed */
    height: 50px; /* Adjust button height as needed */
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #4CAF50;
    border: none;
    border-radius: 50px; /* Half of the button height to create rounded ends */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .donate-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }













 /* Default styles for .donate-buttonheader */
.donate-buttonheader {
    width: 120px; /* Adjust button width as needed */
    height: 50px; /* Adjust button height as needed */
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #4CAF50;
    border: none;
    border-radius: 50px; /* Half of the button height to create rounded ends */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 5px;
  }
  
  .donate-buttonheader:hover {
    background-color: #45a049;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Media query to hide .donate-buttonheader on mobile and tablet */
  @media only screen and (max-width: 988px) {
    .donate-buttonheader {
      display: none;
    }
  }








  

  .membershipcolumn{
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .membershipcolumnt{
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
   
  }







  .donate-buttonheadermob {
    display: none; /* Hide the button by default */
  }
  
  /* Styles for mobile and tablet devices */
  @media screen and (max-width: 992px) {
    .donate-buttonheadermob {
      display: block; /* Show the button on mobile and tablet devices */
      width: 120px; /* Adjust button width as needed */
      height: 50px; /* Adjust button height as needed */
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      background-color: #4CAF50;
      border: none;
      border-radius: 50px; /* Half of the button height to create rounded ends */
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;
    }
  
    .donate-buttonheadermob:hover {
      background-color: #45a049;
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    .membershipcolumn{
        flex-direction: column;
    }

    .membershipcolumnt{

        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        flex-direction: column;
    }
  }

.gpaytexts{
    text-align: center;
}
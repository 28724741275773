.ol{
    width: 80%;
    margin: 0 auto;
}
.second-mcontent h4{
    text-align: center;
}
h2{
    text-align: center;
}

ol li span{
    font-weight: bold;
}

ol{
    width: 75%;
margin: 0 auto;
}
.second-mcontent p {
    text-align: left;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
}
.wellnesspoints{
    width: 23%;
    text-align: center;
    margin: 0 auto;
}

.second-mcontent span {
    text-align: center;
    /* color: blue; */
    width: 23%;
    margin: auto;
    margin-bottom: 30px;
}


.tone {
    text-align: center;
}

  
@media only screen and (max-width: 768px) {


    ol{
        width: 98%;
    }
    .wellnesspoints {
        width: 100%;
    }
}

.goalpointsp{
    text-align: left;
    width: 80%;

    margin: 0 auto;
    padding: 20px;
  }

  .goalpoints{
    text-align: left;
    width: 90%;
  }


  
@media only screen and (max-width: 768px) {
    
.goalpointsp{
    width: 98%;
}
}
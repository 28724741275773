 


.description{
  
    color: #ffffff;
    /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.778); */
        /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
    }
    
    
    .carousel{
        width: 70%;
        margin: 0 auto;
    }
    .carousel-inner{
    background-color: white;
    }
    
    .carousel-control-next, .carousel-control-prev {
        /* background-color: rgb(0, 0, 0); */
        top: 145px;
        bottom: 107px;
    }
    
    .carouselllll{
        width: 100%;
    }
    
    .carousel-indicators {
    
        visibility: hidden;
    }
    
    .slide img {
        width: 100%;
    }



    .description h3{
        font-size: 15px;  
        font-weight: 600;  
        bottom: -30px;
        padding: 0;
        text-shadow: 2px 2px 5px black;
    }

    .description p{
        position: absolute;
        font-size: 12px;
        right: 15%;
        bottom: -41px;
        left: 15%;
        /* background-color: rgb(206, 206, 206); */
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #ffffff;
        text-align: center;
        text-shadow: 2px 2px 5px black;
    }
    
    @media only screen and (max-width: 768px){
    
        .carousel{
            width: 100%;
            margin: 0 auto;
        }
        .description h3{
            font-size: 7px;  
            font-weight: 600;  
            bottom: -30px;
            padding: 0;
            text-shadow: 2px 2px 5px black;
        }
    
        .description p{
            position: absolute;
            font-size: 9px;
            right: 15%;
            bottom: -41px;
            left: 15%;
            /* background-color: rgb(206, 206, 206); */
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            color: #ffffff;
            text-align: center;
            text-shadow: 2px 2px 5px black;
        }
    }
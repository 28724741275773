
.heroti{
    width: 85%;
    margin: 0% auto;
    text-align: center;
    color: #66bb6a;
}

.poinnts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tobebold{
    font-weight: bold;
    color: #0a3d0c;

}
.heroti h2{
    margin-bottom: 20px;
}
.heroti img{
    width: 500px;

}
li{
    margin-bottom: 10px;
}

.projectover{
    width: 85%;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;

}
h2{
    color: #66bb6a;
}
h4{
    color: #66bb6a;
}

.benefits{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.benefits span{
    font-weight: bold;
    color: #0a3d0c;

}

.benefits p{
    /* background-color: #66bb6a; */
    width: 85%;
    text-align: center;
}

.freecenter{
    text-align: center;
    font-weight: 600;
}

@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Apply the animation to the element you want to float */
  .float-element {
    animation: float 3s ease-in-out infinite; /* Adjust the duration and timing function as needed */
  }



  @media only screen and (max-width: 768px) {
    .float-element img{
       width: 360px;
       height: auto;

    }
    li{
        text-align: left;
    }
}
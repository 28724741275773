.structuretit{
    color: #66BB6A;
    font-weight: bold;
    text-align: center;
}
.heightt img{
    width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 30px;

}
.Tabs{
    width:800px;
    /* height:; */
    margin: auto;
  }
  .divimg{
    width:100%;                   
    height: 800px;
    /* overflow-y:scroll; */
    /* margin-bottom: 150px; */
  }
  .Tabs img{
 
    width: 100%;
  }

.divimg{
    display: flex;
    justify-content: center;
   
    background-color: #66BB6A;
}
.divimg img{
    width: 100%;
}





@media only screen and (max-width: 768px){
   
    .heightt img{
        width: 700px;
    }
}





@media only screen and (max-width: 480px) {

  
    .heightt img{
        /* width: 360px; */
    }
    .Tabs{
        width:400px;
        /* height:; */
        /* background-color: #66BB6A; */
        margin: auto;
      }
       .divimg{
    width:100%;                   
    height: 300px;
    /* overflow-y:scroll; */
    /* margin-bottom: 150px; */
  }
}

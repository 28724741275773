.pathtemp img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid rgb(255, 255, 255);
    /* padding: 5px; */

}

.pathtemp{
    display: flex;
    align-items: center;
    background-color: rgb(223, 223, 223);
    /* width: 50%; */
    border-radius: 10px;
    padding: 5%;
    
    margin-bottom: 15px;
    margin-right: 15px;
}
.pathtemp h6,p {
    /* margin-left: 15px; */
    text-align: left;
}

.pathh{
    display: flex;
    margin: 5px;
    padding: 10px;
    /* align-items: center; */
}


@media only screen and (max-width: 768px) {
    .pathh{
        flex-direction: column;
    }

    .pathtemp h6, p {
        text-align: left;
    }
}
.welcomenri p span{
 font-weight: bold;

}

.nributton{
    display: flex;
    justify-content: center;
    align-items: center;
}

.donate-buttonheadert{
    
        width: 180px;  
        height: 50px; /* Adjust button height as needed */
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #4CAF50;
        border: none;
        border-radius: 50px; /* Half of the button height to create rounded ends */
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        margin: 20px;
      }
      
      .donate-buttonheadert:hover {
        background-color: #45a049;
        transform: scale(1.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }

     

      .welcomenriimg{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .welcomenriimg img {
        max-width: 100%;
        height: auto; /* Ensures image maintains aspect ratio */
       /* Removes any default inline alignment */
        padding: 10px;
      }
      
      /* Media query for smaller screens */
      @media screen and (max-width: 768px) {
        .welcomenriimg img {
          padding: 5px; /* Adjust padding for smaller screens */
        }

        
      .welcomenriimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .social-ma  h1{
        font-size: 40px;
      }
      }
.multicard .Card{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.multicardshadow{
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto ;
}
iframe{
margin-top: 0px;
margin-bottom: 0px;
}    

.recentcaro {
    width: 90%;
    margin: 0 auto ;
}

.recentc{
    width: 90%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}


/* Feedback.css */

.carousel-container {
    position: relative;
  }
  
  .multicard {
    transition: transform 0.5s ease; /* Add a transition effect */
    margin-right: 15px; /* Add margin between cards (adjust as needed) */
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  /* Optional: You can add a hover effect to make the card stand out */
  .multicard:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  /* Optional: You can add a box shadow to the cards for a 3D effect */
  .multicardshadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Optional: You can adjust the height of the carousel container to control spacing */
  .carousel-container {
    margin-bottom: 20px; /* Add margin to the bottom (adjust as needed) */
    margin-top: 20px;
  }
  
  /* Optional: Center the cards within the carousel container */
  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  














  /* Feedback.css */
 
  
  .recentc {
    flex: 0 0 auto;
    margin-right: 15px; /* Add margin between carousel items (adjust as needed) */
    box-sizing: border-box;
    transition: transform 0.5s ease;
  }
  
  /* Apply a translateX transformation only to the active carousel item */
  .carousel-item-active .recentc {
    transform: translateX(20px); /* Adjust the translateX value as needed */
  }
  




 
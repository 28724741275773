.cocounut-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
#cohead{
    margin-top: 50px;
text-align: center;
/* font-family: Philosopher; */
font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
/* font-family: 'Signika', sans-serif; */
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
margin-bottom: 50px;
}
.cocounut h1{
    text-align: center;
    margin: 50px 50px;
    margin-bottom: 50px;
}
.Rise-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 50px;

}
.Rise h1{
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
}


@media only screen and (max-width: 768px)  {

    .Rise-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 1200px;
       

    
    }
    .cocounut-card{
        display: flex;
        flex-direction: column;
         justify-content: space-between;
         height: 1200px;
    }

}
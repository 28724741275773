.social-main h1{

    text-align: center;
    /* font-weight: 300; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
    /* font-family: Philosopher; */
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
    }

    .zha-photo {
        display: flex;
        justify-content: space-evenly;
     
    }

    .img-Container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center ;
    }
    .img-Container h6{
        color: rgb(61, 61, 61);
    }

    .photo-farmer{
        margin-bottom: 30px;
    }

.zha-photo img{
    border: 4px solid #66BB6A;;
  width: 200px;
  height: 200px;
  border-radius: 50%;
    }
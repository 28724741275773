.social-main h1{

    text-align: center;
    /* font-weight: 300; */
    /* font-family: Philosopher; */
    /* font-family: 'Signika', sans-serif; */
    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;
    
font-size: 45px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
/* text-align: left; */
color: #66BB6A;
    }
.social-content{
display: flex;
flex-direction: row;
justify-content: space-evenly;
}

.cardtitle{
    text-align: center;
    color:  #000000;;
}
.cardtitle span{
    color: #66BB6A;
}

.part2 button{
    align-self: center;
    justify-content: center;
    align-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cards{
    /* background-color:brown; */
    display: flex;
    /* margin-left: 20px; */
}

.cards .card{
    background-color: #c3ffc6;
    margin: 10px;
    /* margin-left: 20px;
    margin-bottom: 20px; */
}

.cards2{
 
    /* background-color:brown; */
    display: flex;
    margin-bottom: 20px;
    /* margin-left: 20px; */
}

.cards2 .card{
    background-color: #c3ffc6;
    margin: 10px;
    /* margin-left: 20px; */
}
.titleimg{
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    height: 300px;
 
}

.titlesponsor img{
    width: 100%;  
    margin-bottom: 30px;
}
.titlesponsor{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    justify-content:center ;
    align-items: center;
    text-align: center;
}

.cards{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    /* align-items: center; */
}
.cards2{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    /* align-items: center; */
}



.orgfar{
   display: flex;
   flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
   
}

.Tab{
    list-style: none;
}

.orgfar img{
    width: 400px;
}



   @media only screen and (max-width: 768px) {

    .cards{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
     .cards2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .social-content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
   }

   @media only screen and (max-width: 480px) {

  
}

  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Philosopher&display=swap');
.main-content{
width: 80%;
margin: 0 auto;
display: flex;
align-items: center;
flex-direction: column;
}
.main-content h1{
    color:#66BB6A;
    text-align: center;
    /* font-family: Philosopher; */

    font-family: "SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif;

    /* font-family: 'Signika', sans-serif; */
    margin-top: 30px;
    /* font-size: 3rem; */
    font-size: 45px;
    font-weight: 700;
    
}

.feedback{
  width: 70%;
  margin: 0 auto;
}
.unique p{
  text-align: center;
  color: rgb(99, 0, 99);
  
}

.recue{
  width: 600px;
  display: flex;
  margin: 0 auto;
  justify-content:center ;
  align-items: center;
}

#droplang{
background-color: white;
color: #66BB6A;
border: none;
margin-right: 15px;
}
.droplangslide a{
  text-decoration: none;
  color: #505050;
}


#language{
  /* background-color: aqua; */
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  /* background-color: #66BB6A; */
}
#language a{
  text-decoration: none;
  font-size: 15px;
  color: #66BB6A;
  margin-right: 20PX;
  /* align-items: flex-end; */
}
.main-content p{
  text-align: center;
    font-size: 20px;
   
    /* font-family: Roboto; */
}
.hmain img{

  /* margin: auto ; */
    /* margin-left:300px; */
    /* margin-top: 50px; */
}
.img-align{
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  /* background-color: #282828; */
  
}
.img-align img{
  width: 500px; 
}
.Homecards{
  display:flex;
  justify-content:space-around; 
  

  /* margin: 0 auto; */
 
  flex-direction: row;
  /* align-items: center; */
  
}
.Homecards a{
  color: #66BB6A;
  text-decoration: none;
}
.Homecards .Hcard{
  width: 380px;
  /* border: 2px solid black; */
  /* box-shadow: 10px 5px 5px  rgba(0, 0, 0, 0.212); */
  justify-content: space-between;
  margin-bottom: 50px;
margin-top: 50px;
margin-left: 50px;
margin-right: 50px; 
border-style: solid;
border-width: 0.5px;
border-color: rgb(98, 98, 98);
}
.Homecards .Hcard:hover{
  box-shadow: 7px 7px 7px 0 rgba(0, 0, 0, 0.2);

  
}
.Homecards img{
  width:378px; 
  margin: auto;
  /* margin-left: 40px; */
}
.Homecards p{
  padding: 10px 10px;
  margin-top: 10px;
  font-weight: 400;
  size: 14px;
  line-height: 20px;
  color: #505050;
  text-align: left;
}
.Homecards{
  
  align-items: center;
  justify-content: center;
}

.whoweare{
  margin-top: 80px;
  margin-bottom: 30px;
  
  display: flex;
  justify-content: center;
  text-align: center;
}
.whoweare h4{
  font-size: 2.5rem;
  margin-left: 5px;
}
.whoweare .bar{

  background-color: #66BB6A;
  width: 5px;
  height: 50px;
}

.whoweare h4{
  color:rgb(50, 50, 50) ;
}


.videoplayerr{
  width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.ovall-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* height: 100vh; 100% of the viewport height */
}

.ovall-video{
  max-width: 100%; /* Ensure the video doesn't exceed its container */
  max-height: 100%; /* Ensure the video doesn't exceed its container */
}











.oval-container {
  width: 300px; /* Adjust the width and height as needed */
  height: 300px;
  /* border-radius: 5%; */
  overflow: hidden;
  position: relative;

}

.oval-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.videoplayer{
  text-align: center;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 40px ;
  background-color: #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}


/* Apply a box shadow when hovering over .videoplayer */
.videoplayer:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Change the shadow color and size as desired */
  transition: box-shadow 0.3s ease; /* Add a smooth transition effect */
}

.videoplayer{
  margin-top: 10px;
  
}
.carousel-inner{
  background-color: #aeaeae;
  padding-top: 20px;
  margin-bottom: 20px;


}

.Carousel.Item {

  background-color: #153917;
}

.visionmi{
  width: 70%;
  margin: 0 auto;
  /* padding-left: 80px;
  padding-right: 80px; */
  align-items: center;
  text-align: left;
}


.visionmi h4{
  padding-bottom: 10px;

}


.goalpoints{
      text-align: left;
      width: 90%;
      margin: 0 auto;
      padding: 20px;
   /* transform: translateX(20%); */
}
.goalpointsp{
  text-align: left;
  width: 60%;
  margin: 0 auto;
  padding: 20px;
}
.goalpoints span{
font-weight: bold;
color: #1D1D1F;
}

.carousel-control-next span{
  color: #3b653d;
}

.VdoCaro{
  /* background-color: #aeaeae; */
}

.visionmi h4{
text-align: center;
}

.wellnesspoint{
  text-align: center;
width: 90%;
margin: 0 auto;

}

.operatingpoint{
  

}
.recuee{
  width: 750px;
  display: flex;
  margin: 0 auto;
  justify-content:center ;
  align-items: center;
}
.recuee img{
  width: 750px;
}

.img-align-ce{
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

 
  
}
.img-align-ce img{
  width: 650px;
}



@media only screen and (max-width: 768px) {


  .feedback{
    width: 100%;
    margin: 0 auto;
  }
  .main-content h1{
    margin-top: 20px;
    font-size: 2.5rem;
  }
    .hmain img{
      margin: 0 auto;
      display: block;
      width: 70%;
      margin-bottom: 50px;
    
      
    }


    .videoplayerr{
      width: 90%;
      margin: 0 auto;
      margin-bottom: 20px;
    }


    .main-content{
        width: 80%;
        margin: 0 auto;
        display: block;
        align-items: center;
        flex-direction: column;
        }

        .Homecards{
       
          flex-direction: column;
          text-align: center;
          overflow-x: hidden;
        }
        .Homecards .Hcard{
         
      justify-content: center;
          align-items: center;
        }
        .Homecards .Hcard p{
          text-align: left;

        }
        .Homecards .Hcard:hover{
          transform:none;
        }
        #language a{
          margin-right: 15px;
        }

        .videoplayer{
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .oval-container {
          /* width: 300px; Adjust the width and height as needed */
          height: 300px;
          margin-bottom: 20px;
          margin-top: 20px;
          border-radius: 5%;
        }


        .visionmi{
          width: 100%;
          margin: 0 auto;
          padding-left: 20px;
          padding-right: 20px;
          align-items: center;
          text-align: left;
        }
        
        
        .visionmi h4{
          padding-bottom: 10px;
        
        }
        
        
        .goalpoints{
              text-align: left;
              width: 90%;
              margin: 0 auto;
              padding: 20px;
           /* transform: translateX(20%); */
        }
        .goalpointsp{
          text-align: left;
          width: 90%;
          margin: 0 auto;
          padding: 20px;
        }

        .recue{
          width: 350px;
          display: flex;
          margin: 0 auto;
          justify-content:center ;
          align-items: center;
        }
        .recuee{
          width: 350px;
          display: flex;
          margin: 0 auto;
          justify-content:center ;
          align-items: center;
        }
        .recuee img{
          width: 350px;
          
        }

        .img-align-ce{
          /* background-color: aqua; */
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        
         
          
        }
        .img-align-ce img{
          width: 350px;
        }
        
      }

@media only screen and (max-width: 380px) {
  .main-content{
    /* width: 80%; */
    margin: 0 auto;
   align-items: center;
   justify-content: center;
    }
  .main-content h1{
    margin-top: 50px;
    /* font-size: 1.8rem; */
    text-align: center;
  }
  .main-content p{
    text-align: center;
    padding: 10px 10px;
    /* font-family: none; */
  }
  .Homecards .Hcard{
  
    justify-content: center;

   align-content: center;
    
    /* align-items: center; */
  }
  .Homecards .Hcard:hover{
    transform:none;
    
  }

  .Homecards{
    /* width: 80%; */
  }
  #language a{
    margin-right: 2px;
  }
  
}
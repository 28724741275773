/* Custom styles for Carousel controls and indicators */
.carousel-control-prev,
.carousel-control-next,
.carousel-indicators li {
  /* background-color: rgb(54, 54, 54); */
}
/* Custom styles for Carousel container aspect ratio */
.carousel {
    /* max-width: 800px; Set your desired max-width */
    margin: auto;
  }
  
  .carousel-inner {
    /* height: 300px; Set your desired height */
  }
  
  .carousel-item {
    /* Adjust styles for individual items as needed */
  }

  .slide img {
    width: 100%;
}



.carouselll{
    width: 70%;
    margin: 0 auto;
}


.description h3{
  
    color: #ffffff;
    /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.778); */
        /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
    }
    

    .carousellll{
        /* width: 80%;
        margin: 0 auto; */
        /* background-color: aquamarine; */
    }
    
    .carousel{
        width: 70%;
        margin: 0 auto;
    }
    .carousel-inner{
    background-color: #ffffff;
    }
    
    .carousel-control-next, .carousel-control-prev { 
        display: flex;
    }
    
    .carousel-indicators {
    
        visibility: hidden;
    }

    .carousel-control-next, .carousel-control-prev {
        top: 173px;
    bottom: 109px;
    }

    
    
    @media only screen and (max-width: 768px){
    
        .carousel{
            width: 100%;
            margin: 0 auto;
        }
        .description h3{
            font-size: 15px;    
        }
    
        .description p{
            position: absolute;
            font-size: 9px;
            right: 15%;
            bottom: 5px;
            left: 15%;
            /* padding-top: 1.25rem; */
            /* padding-bottom: 1.25rem; */
            color: #ffffff;
            text-align: center;
        }
        .carouselll{
            width: 100%;
        }
    }
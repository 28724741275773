 
 

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    height: auto;
    margin: 60px;
    background-color: rgb(255, 255, 255);
    
}

.box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
}

input[type='number'] {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.backwalldonate {
    /* Specify the background image URL */
    background-image: url('https://img.freepik.com/free-photo/people-holding-rubber-heart_1150-18576.jpg?t=st=1709533219~exp=1709536819~hmac=7b4726b6f154a11847d4474cf20f5eb81f9ed7ae9cf0005f9245ec5cf89833dd&w=1060');
    
    /* Set background size and position */
    position: absolute;
    background-size: cover;
    background-position: center;
    
    /* Add any other background styles as needed */
    /* Example: */
    /* background-color: #f0f0f0; */
}


.amount-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.amount-buttons button {
    margin: 10px;
    padding: 15px 30px; /* Adjust padding for larger buttons */
    border: none;
    border-radius: 8px;
    background-color: #000000;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 18px; /* Larger font size for amounts */
    width: 200px; /* Set a fixed width for all buttons */
}

.amount-buttons button:hover {
    background-color: #787878;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Add glossy effect on hover */
}

.amount-buttons button.clicked {
    background-color: rgb(208, 208, 208); /* Change color to black when clicked */
}

.premium-text {
    font-size: 14px;
    color: #555;
}

 
.next-icon-container {
    position: relative;
    display: inline-block;
  }
  
  .oval-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px; /* Adjust the width as needed */
    height: 32px; /* Adjust the height as needed */
    background-color: #007bff; /* Background color of the oval */
    border-radius: 50%;
    transform: translateX(50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .next-icon {
    color: #ffffff; /* Color of the arrow icon */
    font-size: 18px; /* Adjust the font size as needed */
  }
  

  .carousel-control-next-icon{
    /* color: #007bff; */
    background-image: url('../../public/img/next.png');
    /* background-color: black; */
    opacity: 1;
    transition: opacity .15s ease;
  }

  .carousel-control-prev-icon{
    /* color: #007bff; */
    background-image: url('../../public/img/prev.png');
    /* background-color: black; */
    opacity: 1;
    /* transition: opacity .15s ease; */
  }